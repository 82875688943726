import React from 'react';
import PropTypes from 'prop-types';
import { Link as GatsbyLink } from 'gatsby';
import Scroll from 'react-scroll';
import makeStyles from '@material-ui/core/styles/makeStyles';
import useTheme from '@material-ui/core/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { isAfter } from 'date-fns';

import Container from '~components/Container';
import { HeroTitle, Title, Text } from '~components/Typography';
import RichText from '~components/RichText';
import ButtonSubmit from '~components/form-buttons/ButtonSubmit';

import { makeLinkTargetNameFromTitle } from '~utils/unfurl-helpers';

const GoToSection = Scroll.Link;

const useStyles = makeStyles(theme => ({
  heroTextColor: props => ({
    color: props.text_color
  }),

  prevNextButton: props => ({
    backgroundColor: props.hero_background_color,
    color: props.text_color,

    '&:hover': {
      backgroundColor: 'rgba(255,255,255,0.25)'
    }
  }),

  navSection: {
    padding: theme.spacing(4, 6),
    background: 'rgba(0, 0, 0, 0.4)',
    borderRadius: '8px',
    '& ul': {
      listStyle: 'none',
      margin: 0,
      padding: 0,
      '& li': {
        color: '#ffffff',
        listStyleType: 'none',
        '&:before': {
          marginRight: theme.spacing(),
          content: "'.'",
          fontSize: '64px',
          lineHeight: '16px'
        },
        '&:hover': {
          cursor: 'pointer'
        }
      }
    }
  }
}));

const getParshaByName = (parshaName, listOfParshas) =>
  listOfParshas.find(parsha => parsha.title === parshaName);

const getPreviousParsha = (currentParsha, listOfParshas) => {
  const sortedListOfParshas = [...listOfParshas];

  sortedListOfParshas.sort((leftParsha, rightParsha) =>
    isAfter(new Date(rightParsha.start_date), new Date(leftParsha.start_date)) ? -1 : 1
  );

  const prevParshaIndex =
    sortedListOfParshas.findIndex(parsha => parsha.title === currentParsha.title) - 1;

  if (prevParshaIndex < 0) {
    return null;
  }

  return sortedListOfParshas[prevParshaIndex];
};

const getNextParsha = (currentParsha, listOfParshas) => {
  const sortedListOfParshas = [...listOfParshas];

  sortedListOfParshas.sort((leftParsha, rightParsha) =>
    isAfter(new Date(rightParsha.start_date), new Date(leftParsha.start_date)) ? -1 : 1
  );

  const nextParshaIndex =
    sortedListOfParshas.findIndex(parsha => parsha.title === currentParsha.title) + 1;

  if (nextParshaIndex >= listOfParshas.length) {
    return null;
  }

  return sortedListOfParshas[nextParshaIndex];
};

const HeroTextToc = ({
  title,
  overline_rich_text,
  underline_rich_text,
  description,
  hero_background_color,
  hero_background_image,
  navigation_menu_title,
  text_color,
  navlinks,
  listOfParshas,
  currentParshaName,
  ...rest
}) => {
  const theme = useTheme();
  const isSmallerThanMd = useMediaQuery(theme.breakpoints.down('md'));
  const classes = useStyles({ text_color, hero_background_color });
  const contentsTitle = navigation_menu_title || 'Contents';

  const hasListOfParshas = listOfParshas && listOfParshas.length && listOfParshas.length > 0;

  const currentParsha = hasListOfParshas && getParshaByName(currentParshaName, listOfParshas);
  const previousParsha =
    hasListOfParshas && currentParsha && getPreviousParsha(currentParsha, listOfParshas);
  const nextParsha =
    hasListOfParshas && currentParsha && getNextParsha(currentParsha, listOfParshas);

  return (
    <Container
      center
      size={33}
      background={
        hero_background_image && hero_background_image.url
          ? `url(${hero_background_image.url})`
          : hero_background_color
      }
      {...rest}
    >
      <Box minWidth="80%" py={isSmallerThanMd ? 3 : 2}>
        <Grid
          container
          direction="row"
          alignItems="center"
          justify="space-between"
          spacing={isSmallerThanMd ? 4 : 0}
        >
          <Grid item xs={12} md={6} lg={6}>
            <Box pt={3} pb={isSmallerThanMd ? 0 : 3}>
              {overline_rich_text && overline_rich_text.html && (
                <RichText
                  mode="heroOverlineHtml"
                  text_color={text_color}
                  html={overline_rich_text.html}
                />
              )}

              <HeroTitle className={classes.heroTextColor}>{title}</HeroTitle>

              {underline_rich_text && underline_rich_text.html && (
                <RichText
                  mode="heroUnderlineHtml"
                  text_color={text_color}
                  html={underline_rich_text.html}
                />
              )}

              {description && (
                <Box mt={2}>
                  <Text className={classes.heroTextColor}>{description}</Text>
                </Box>
              )}
            </Box>
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            {navlinks && !!navlinks.length && (
              <Box component="div" borderRadius="4" boxShadow={10} className={classes.navSection}>
                <Title componentElementName="h2">{contentsTitle}</Title>
                <ul>
                  {navlinks
                    .filter(link => !!link)
                    .map((link, index) => (
                      <li key={`navigation-link-${link}-${index + 1}`}>
                        <Text
                          component={GoToSection}
                          to={makeLinkTargetNameFromTitle(link)}
                          smooth
                          offset={-48}
                          duration={500}
                        >
                          {link}
                        </Text>
                      </li>
                    ))}
                </ul>
              </Box>
            )}
          </Grid>
        </Grid>
      </Box>
      <Box
        component="div"
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        width="100%"
        alignItems="center"
      >
        {hasListOfParshas && previousParsha && previousParsha.link && (
          <ButtonSubmit
            component={GatsbyLink}
            to={previousParsha.link}
            type="button"
            fullWidth={false}
            className={classes.prevNextButton}
            variant="text"
          >
            Previous Parsha
          </ButtonSubmit>
        )}
        {hasListOfParshas && nextParsha && nextParsha.link && (
          <ButtonSubmit
            component={GatsbyLink}
            to={nextParsha.link}
            type="button"
            fullWidth={false}
            className={classes.prevNextButton}
            variant="text"
          >
            Next Parsha
          </ButtonSubmit>
        )}
      </Box>
    </Container>
  );
};

HeroTextToc.propTypes = {
  title: PropTypes.string.isRequired,
  overline_rich_text: PropTypes.shape({ html: PropTypes.string, text: PropTypes.string }),
  underline_rich_text: PropTypes.shape({ html: PropTypes.string, text: PropTypes.string }),
  description: PropTypes.string,
  navigation_menu_title: PropTypes.string,
  text_color: PropTypes.string,
  hero_background_color: PropTypes.string,
  hero_background_image: PropTypes.shape({ url: PropTypes.string }),
  navlinks: PropTypes.arrayOf(PropTypes.string),
  listOfParshas: PropTypes.arrayOf(PropTypes.shape({})),
  currentParshaName: PropTypes.string
};

HeroTextToc.defaultProps = {
  overline_rich_text: {},
  underline_rich_text: {},
  description: null,
  navigation_menu_title: null,
  text_color: '#ffffff',
  hero_background_color: '#3787FB',
  hero_background_image: {},
  navlinks: [],
  currentParshaName: null,
  listOfParshas: null
};

export default HeroTextToc;
